<template>
    <Button label="Nova Ocorrência" icon="pi pi-plus" iconPos="left" @click="abrir()" class="p-button" />
    <ocorrencia-form :mostrar="mostrar" :reset="resetForm" titulo="Nova Ocorrência" @cancelar="fechar()" @salvar="inserir($event)" :erros="erros" @resetado="resetForm = false" :credor="credor"></ocorrencia-form>
</template>

<script>
import OcorrenciaForm from './OcorrenciaForm';
import OcorrenciasServices from './services';

export default {
    props: {
        credor: {
            type: Object,
        },
    },

    components: {
        OcorrenciaForm,
    },

    emits: ['atualizar', 'atualizarOcorrencias'],

    data() {
        return {
            mostrar: false,
            erros: [],
            resetForm: false,
        };
    },

    methods: {
        abrir() {
            this.mostrar = true;
        },

        fechar() {
            if (this.mostrar) {
                this.mostrar = false;
            }
            this.erros = [];
        },

        reset() {
            this.resetForm = true;
            this.erros = [];
        },

        inserir(ocorrenciaInserida) {
            this.$store.dispatch('addRequest');
            let ocorrenciaDto = {
                credorId: this.$route.params.id,
                tipoOcorrenciaId: ocorrenciaInserida.tipoOcorrenciaId,
                dataOcorrencia: this.$moment(ocorrenciaInserida.dataOcorrencia).format('YYYY-MM-DD'),
                texto: ocorrenciaInserida.texto,
                proximoContato: ocorrenciaInserida.proximoContato ? this.$moment(ocorrenciaInserida.proximoContato).format('YYYY-MM-DDTHH:mm') : null,
            };
            OcorrenciasServices.inserir(ocorrenciaDto).then((r) => {
                if (r) {
                    if (r.success) {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Nova ocorrência',
                            detail: 'Ocorrência salva com sucesso',
                            life: 3000,
                        });
                        this.$emit('atualizarOcorrencias', r.data);
                        this.reset();
                        this.fechar();
                    } else {
                        this.erros = r.errors;
                    }
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
