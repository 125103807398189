<template>
    <detalhe titulo="Tipo Pessoa" :size="size">{{ credor?.tipoPessoa == 'PF' ? 'PESSOA FÍSICA' : 'PESSOA JURÍDICA' }}</detalhe>
    <detalhe :titulo="credor?.tipoPessoa == 'PF' ? 'CPF' : 'CNPJ'" :size="size">{{
        credor?.tipoPessoa == 'PF' ? $toCpf(credor?.cpfCnpj) : $toCnpj(credor?.cpfCnpj)
    }}</detalhe>
    <detalhe titulo="Identidade" :size="size" v-if="credor?.tipoPessoa == 'PF'"
        >{{ credor?.identidade }} - {{ credor?.identidadeOrgaoEmissor }}/{{ credor?.identidadeUfEmissora }}</detalhe
    >
    <detalhe titulo="Contato" :size="size">{{ credor?.contato }}</detalhe>
    <detalhe titulo="Telefones" :size="size"
        >{{ $toTel(credor?.telefone1) }}
        <span v-if="credor?.telefone2">/ {{ $toTel(credor?.telefone2) }}</span>
        <span v-if="credor?.telefone3">/ {{ $toTel(credor?.telefone3) }}</span>
        <span v-if="credor?.telefone4">/ {{ $toTel(credor?.telefone4) }}</span></detalhe
    >
    <detalhe titulo="Emails" :size="size"
        >{{ credor?.email1 }}
        <span v-if="credor?.email2">/ {{ credor?.email2 }}</span>
        <span v-if="credor?.email3">/ {{ credor?.email3 }}</span>
        <span v-if="credor?.email4">/ {{ credor?.email4 }}</span></detalhe
    >
    <endereco :endereco="credor?.endereco" :size="size"></endereco>
    <detalhe titulo="Atualizado em" :size="size">{{ $dateFormat(credor?.dataAtualizacao, 'DD/MM/YYYY HH:mm') }}</detalhe>
</template>

<script>
import Endereco from '../../shared/components/EnderecoComponent';

export default {
    props: {
        projeto: {
            type: Object,
        },

        credor: {
            type: Object,
        },
    },

    components: {
        Endereco,
    },

    data() {
        return {
            size: '150',
        };
    },
};
</script>
