import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PROJETOS_PORT}${process.env.VUE_APP_API_PATH}`;
const urlBase = `${api}/credores`;

export default {
    obterTiposOcorrencias() {
        return axiosJwt.get(`${urlBase}/ocorrencias/tipo`);
    },

    obterOcorrenciaPorId(id) {
        return axiosJwt.get(`${urlBase}/ocorrencias/${id}`);
    },

    inserir(ocorrencia) {
        return axiosJwt.post(`${urlBase}/ocorrencias/inserir`, ocorrencia);
    },

    atualizar(ocorrencia) {
        return axiosJwt.post(`${urlBase}/ocorrencias/atualizar`, ocorrencia);
    },

    excluir(ocorrencia) {
        return axiosJwt.post(`${urlBase}/ocorrencias/excluir`, ocorrencia);
    },
};
