import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PROJETOS_PORT}${process.env.VUE_APP_API_PATH}`;
const urlBase = `${api}/credores`;

export default {
    obterProjetos() {
        return axiosJwt.get(`${urlBase}/projetos`);
    },

    obterProjetoPorId(projetoId) {
        return axiosJwt.get(`${urlBase}/projetos/${projetoId}/detalhar`);
    },

    obterCredores(projetoId) {
        return axiosJwt.get(`${urlBase}/projetos/${projetoId}`);
    },

    obterOpcoesFormulario(tipoProjeto) {
        return axiosJwt.get(`${urlBase}/opcoesformulario/${tipoProjeto}`);
    },

    obterCredorPorId(id) {
        return axiosJwt.get(`${urlBase}/${id}`);
    },

    inserirCredor(credor) {
        return axiosJwt.post(`${urlBase}/inserir`, credor);
    },

    atualizarCredor(credor) {
        return axiosJwt.post(`${urlBase}/atualizar`, credor);
    },

    exportarCredores(credoresId) {
        return axiosJwt.post(`${urlBase}/exportar`, credoresId, {
            responseType: 'blob',
        });
    },

    emitirContrato(credorId) {
        return axiosJwt.get(`${urlBase}/${credorId}/emitircontrato`, {
            responseType: 'blob',
        });
    },
};
