<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <div class="p-d-flex">
                    <h5><i class="pi pi-copy"></i> Consulta</h5>
                    <btn-refresh @click="obterConsulta()"></btn-refresh>
                </div>
                <btn-voltar btnTitle="Fechar" :back="fechar"></btn-voltar>
                <btn-atualizar-credor
                    class="p-ml-2"
                    :credor="credor"
                    :consulta="consulta"
                    @obterCredorAtualizado="$emit('obterCredorAtualizado')"
                ></btn-atualizar-credor>
                <div class="p-mt-2">
                    <detalhe titulo="API" :size="sizeForm">{{ consulta?.tipoDescr }}</detalhe>
                    <detalhe titulo="Consulta" :size="sizeForm">{{ consulta?.codigo }}</detalhe>
                    <detalhe titulo="Status" :size="sizeForm"><status :status="consulta?.statusDescr"></status></detalhe>
                    <detalhe titulo="Atualizado em" :size="size">{{ $dateFormat(consulta?.dataAtualizacao, 'DD/MM/YYYY HH:mm') }}</detalhe>
                    <detalhe titulo="Retorno" :size="sizeForm">
                        <vue-json-pretty
                            :data="JSON.parse(consulta?.retorno)"
                            :showDoubleQuotes="true"
                            v-if="consulta && consulta.retorno && isJsonString(consulta.retorno)"
                        ></vue-json-pretty>
                        <span v-if="consulta && consulta.retorno && !isJsonString(consulta.retorno)">{{ consulta?.retorno }}</span>
                    </detalhe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Service from './services';
import BtnAtualizarCredor from './BtnAtualizarCredor';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
    props: {
        consultaId: {
            type: String,
        },

        credor: {
            type: Object,
        },
    },

    emits: ['fechar', 'obterCredorAtualizado'],

    components: {
        BtnAtualizarCredor,
        VueJsonPretty,
    },

    data() {
        return {
            consulta: null,
        };
    },

    computed: {
        sizeForm() {
            return '100';
        },
    },

    methods: {
        obterConsulta() {
            this.$store.dispatch('addRequest');
            Service.obterPorId(this.consultaId).then((response) => {
                if (response && response.success) {
                    this.consulta = response.data;
                } else {
                    this.consulta = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        isJsonString(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },

        fechar() {
            this.$emit('fechar');
        },
    },

    mounted() {
        if (this.consultaId) {
            this.obterConsulta();
        }
    },
};
</script>
