import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PROJETOS_PORT}${process.env.VUE_APP_API_PATH}`;
const urlBase = `${api}/credores`;

export default {
    emitirContrato(credorId, projetoAnexoId) {
        return axiosJwt.get(`${urlBase}/${credorId}/emitircontrato/${projetoAnexoId}`, {
            responseType: 'blob',
        });
    },
};
