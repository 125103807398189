<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <div class="p-d-flex">
                    <h5>
                        <detalhe titulo="Projeto" size="70">{{ projetoCredor?.nome }} - {{ projetoCredor?.tipoDescr }}</detalhe>
                        <detalhe titulo="Credor" size="70">{{ credor?.nome }}</detalhe>
                    </h5>
                    <btn-refresh @click="obterCredor()"></btn-refresh>
                </div>
                <Message severity="warn" v-if="credor?.proximoContato" :closable="false"
                    >Próximo Contato: <strong>{{ $moment(credor?.proximoContato).format('DD/MM/YYYY HH:mm') }}</strong></Message
                >
                <div class="p-mb-4">
                    <TabView v-model:activeIndex="activeIndex">
                        <TabPanel :header="isCessaoCredito ? 'Dados Crédito' : 'Dados Processo'">
                            <dados-credor
                                :projeto="projetoCredor"
                                :credor="credor"
                                :isCessaoCredito="isCessaoCredito"
                                :isRecuperacaoJudicial="isRecuperacaoJudicial"
                            ></dados-credor>
                        </TabPanel>
                        <TabPanel header="Dados Cadastrais">
                            <dados-cadastrais :projeto="projetoCredor" :credor="credor"></dados-cadastrais>
                        </TabPanel>
                        <TabPanel header="Ocorrências" v-if="mostrarOcorrencias">
                            <ocorrencias :credor="credor" @obterCredorAtualizado="obterCredorAtualizado()"></ocorrencias>
                        </TabPanel>
                        <TabPanel header="Anexos" v-if="mostrarAnexos">
                            <anexos :credor="credor" @obterCredorAtualizado="obterCredorAtualizado()"></anexos>
                        </TabPanel>
                        <TabPanel header="Documentos" v-if="mostrarDocumentos">
                            <documentos :credor="credor"></documentos>
                        </TabPanel>
                        <TabPanel header="Consultas" v-if="mostrarConsultas">
                            <consultas :credor="credor" @obterCredorAtualizado="obterCredorAtualizado()"></consultas>
                        </TabPanel>
                    </TabView>
                </div>
                <btn-atualizar :projeto="projetoCredor" :credor="credor" v-if="activeIndex == 0 || activeIndex == 1"></btn-atualizar>
                <btn-voltar :route="{ name: 'Credores' }"></btn-voltar>
            </div>
        </div>
    </div>
</template>

<script>
import CredoresServices from './services';
import DadosCredor from './DadosCredor';
import DadosCadastrais from './DadosCadastrais';
import BtnAtualizar from './BtnAtualizar';
import Ocorrencias from './ocorrencias/Listagem';
import Anexos from './anexos/Listagem';
import Consultas from './consultas/Listagem';
import Documentos from './documentos/Listagem';

export default {
    props: {
        projeto: {
            type: Object,
        },

        isCessaoCredito: {
            type: Boolean,
            default: () => false,
        },

        isRecuperacaoJudicial: {
            type: Boolean,
            default: () => false,
        },
    },

    components: {
        DadosCredor,
        BtnAtualizar,
        Ocorrencias,
        DadosCadastrais,
        Anexos,
        Consultas,
        Documentos,
    },

    data() {
        return {
            credor: null,
            activeIndex: 0,
            projetoCredor: null,
        };
    },

    methods: {
        obterCredor() {
            this.$store.dispatch('addRequest');
            CredoresServices.obterCredorPorId(this.$route.params.id).then((response) => {
                if (response && response.success) {
                    this.credor = response.data;
                } else {
                    this.credor = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        obterCredorAtualizado() {
            this.obterCredor();
            this.$store.dispatch('setAtualizar', true);
        },

        obterProjeto() {
            this.$store.dispatch('addRequest');
            CredoresServices.obterProjetoPorId(this.$route.params.projetoId).then((response) => {
                if (response && response.success) {
                    this.projetoCredor = response.data;
                } else {
                    this.projetoCredor = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        voltar() {
            this.$router.push({ name: 'Credores' });
        },

        toAtualizar() {
            this.$router.push({
                name: 'Credores_Atualizar',
                params: {
                    id: this.$route.params.id,
                },
            });
        },
    },

    computed: {
        mostrarOcorrencias() {
            return this.$temAcessoView('OCORRENCIAS-00');
        },

        mostrarAnexos() {
            return this.$temAcessoView('CREDORES-ANEXOS-00');
        },

        mostrarConsultas() {
            return this.$temAcessoView('CREDORES-CONSULTAS-00');
        },

        mostrarDocumentos() {
            return this.$temAcessoView('CREDORES-05');
        },
    },

    mounted() {
        this.obterCredor();
        if (!this.projeto) {
            this.obterProjeto();
        } else {
            this.projetoCredor = this.projeto;
        }
    },
};
</script>
