<template>
    <font-awesome-icon icon="edit" @click="abrir()" size="lg" class="icon-button p-text-primary" title="Atualizar" />
    <ocorrencia-form acao="A" :mostrar="mostrar" titulo="Atualizar Ocorrência" :ocorrencia="ocorrencia" @cancelar="fechar()" @salvar="atualizar($event)" :erros="erros" :credor="credor"></ocorrencia-form>
</template>

<script>
import OcorrenciaForm from './OcorrenciaForm';
import OcorrenciasServices from './services';

export default {
    components: {
        OcorrenciaForm,
    },

    props: {
        credor: {
            type: Object,
        },

        ocorrencia: {
            type: Object,
        },
    },

    emits: ['atualizar', 'atualizarOcorrencias'],

    data() {
        return {
            mostrar: false,
            ocorrenciaBase: null,
            erros: [],
        };
    },

    methods: {
        abrir() {
            this.obterOcorrencia();
        },

        fechar() {
            if (this.mostrar) {
                this.mostrar = false;
            }
            this.erros = [];
        },

        obterOcorrencia() {
            this.$store.dispatch('addRequest');
            OcorrenciasServices.obterOcorrenciaPorId(this.ocorrencia.ocorrenciaId).then((r) => {
                if (r) {
                    if (r.success) {
                        this.ocorrenciaBase = r.data;
                        this.mostrar = true;
                    } else {
                        this.ocorrenciaBase = null;
                    }
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar(ocorrenciaAtualizada) {
            this.$store.dispatch('addRequest');
            let ocorrenciaDto = {
                ocorrenciaId: this.ocorrencia.ocorrenciaId,
                credorId: this.$route.params.id,
                tipoOcorrenciaId: ocorrenciaAtualizada.tipoOcorrenciaId,
                dataOcorrencia: this.$moment(ocorrenciaAtualizada.dataOcorrencia).format('YYYY-MM-DD'),
                texto: ocorrenciaAtualizada.texto,
                proximoContato: ocorrenciaAtualizada.proximoContato ? this.$moment(ocorrenciaAtualizada.proximoContato).format('YYYY-MM-DDTHH:mm') : null,
            };
            OcorrenciasServices.atualizar(ocorrenciaDto).then((r) => {
                if (r) {
                    if (r.success) {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Atualização de Ocorrência',
                            detail: 'Ocorrência atualizada com sucesso',
                            life: 3000,
                            group: 'dialog',
                        });
                        this.$emit('atualizarOcorrencias', r.data);
                        this.fechar();
                    } else {
                        this.erros = r.errors;
                    }
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
