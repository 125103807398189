<template>
    <font-awesome-icon icon="download" @click="download()" size="lg" class="icon-button p-ml-2 p-text-primary" title="Download" v-if="$temAcessoView('CREDORES-ANEXOS-00')" />
</template>

<script>
import AnexosService from './services';

export default {
    props: {
        anexo: {
            type: Object,
        },
    },

    methods: {
        download() {
            this.$store.dispatch('addRequest');
            AnexosService.download(this.anexo.credorAnexoId).then((response) => {
                if (response && response.success) {
                    this.$download(response.data, this.anexo.nomeArquivo);
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Projeto',
                        detail: 'Erro ao realizar download do anexo',
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
