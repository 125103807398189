<template>
    <Button
        label="Atualizar Dados do Credor"
        icon="pi pi-refresh"
        iconPos="left"
        @click="confirmarAtualizarCredor()"
        class="p-button"
        v-if="mostrarBtn"
    />
</template>

<script>
import Services from './services';
import { statusConsulta } from './consultas';

export default {
    props: {
        credor: {
            type: Object,
        },

        consulta: {
            type: Object,
        },
    },

    components: {},

    emits: ['obterCredorAtualizado'],

    data() {
        return {};
    },

    methods: {
        confirmarAtualizarCredor() {
            this.$confirm.require({
                message: `Tem certeza que deseja atualizar o credor ${this.credor.nome} com os dados dessa consulta?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.atualizarCredor();
                },
            });
        },

        atualizarCredor() {
            this.$store.dispatch('addRequest');
            let atualizacaoDto = {
                credorId: this.credor.credorId,
                consultaId: this.consulta.consultaId,
            };
            Services.atualizarCredor(atualizacaoDto).then((r) => {
                if (r && r.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Atualizar Credor',
                        detail: 'Credor atualizado com sucesso',
                        life: 3000,
                    });
                    this.$emit('obterCredorAtualizado', r.data);
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Atualizar Credor',
                        detail: r.errors[0],
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    computed: {
        mostrarBtn() {
            return this.$temAcessoView('CREDORES-CONSULTAS-02') && this.consulta?.status == statusConsulta.SUCESSO;
        },
    },
};
</script>
