<template>
    <Dialog v-model:visible="display" :modal="true" @hide="cancelar()" @show="preencher()" :breakpoints="{ '960px': '75vw', '640px': '95vw' }" :style="{ width: '50vw' }" :contentStyle="{ overflow: 'visible' }">
        <template #header>
            <h5>{{ titulo }}</h5>
        </template>
        <erros-box :erros="erros"></erros-box>
        <div class="p-formgrid p-grid p-fluid">
            <div class="p-field p-col-12 p-md-6">
                <label class="required">Tipo</label>
                <Dropdown v-model="ocorrenciaTemp.tipoOcorrenciaId" :options="tiposOcorrencias" optionLabel="descricao" optionValue="tipoOcorrenciaId" />
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label class="required">Data</label>
                <Calendar v-model="ocorrenciaTemp.dataOcorrencia" :monthNavigator="true" :yearNavigator="true" :yearRange="yearRange" @date-select="v$.ocorrenciaTemp.dataOcorrencia.$touch()" :manualInput="false" :disabled="desabilitaForm" />
            </div>
            <div class="p-field p-col-12">
                <label>Detalhe</label>
                <Textarea v-model="ocorrenciaTemp.texto" rows="6" :disabled="desabilitaForm" @input="v$.ocorrenciaTemp.texto.$touch()" />
                <small class="p-error" v-if="v$.ocorrenciaTemp.texto.$dirty"> <span v-if="v$.ocorrenciaTemp.texto.required.$invalid">A descrição da ocorrência é obrigatória</span></small>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label>Próximo Contato</label>
                <Calendar v-model="ocorrenciaTemp.proximoContato" :monthNavigator="true" :yearNavigator="true" :yearRange="yearRange2" :manualInput="false" :showTime="true" :showSeconds="false" :disabled="desabilitaForm" :showButtonBar="true" />
            </div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary" @click="cancelar()" autofocus />
            <Button label="Salvar" icon="pi pi-check" @click="confirmarSalvar()" :disabled="naoPermiteSalvar" />
        </template>
    </Dialog>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import OcorrenciasService from './services';

export default {
    setup() {
        return { v$: useVuelidate() };
    },

    props: {
        mostrar: {
            type: Boolean,
            default: false,
        },

        titulo: {
            type: String,
        },

        ocorrencia: {
            type: Object,
        },

        erros: {
            type: Array,
        },

        reset: {
            type: Boolean,
            default: false,
        },

        acao: {
            type: String,
            default: 'I',
        },

        credor: {
            type: Object,
        },
    },

    emits: ['cancelar', 'salvar', 'resetado'],

    data() {
        return {
            display: false,
            ocorrenciaTemp: {
                tipoOcorrenciaId: null,
                dataOcorrencia: null,
                texto: null,
                proximoContato: null,
            },
            tiposOcorrencias: [],
        };
    },

    validations() {
        return {
            ocorrenciaTemp: {
                tipoOcorrenciaId: { required },
                dataOcorrencia: { required },
                texto: { required },
            },
        };
    },

    methods: {
        obterTiposOcorrencias() {
            this.$store.dispatch('addRequest');
            OcorrenciasService.obterTiposOcorrencias().then((response) => {
                if (response && response.success) {
                    this.tiposOcorrencias = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        cancelar() {
            this.resetar();
            this.$emit('cancelar');
        },

        preencher() {
            if (this.acao === 'A') {
                this.ocorrenciaTemp = {
                    tipoOcorrenciaId: this.ocorrencia.tipoOcorrenciaId,
                    dataOcorrencia: new Date(this.ocorrencia.dataOcorrencia),
                    texto: this.ocorrencia.texto,
                    proximoContato: this.credor.proximoContato ? new Date(this.credor.proximoContato) : this.credor.proximoContato,
                };
                this.v$.$touch();
            } else {
                this.ocorrenciaTemp.dataOcorrencia = new Date();
                this.proximoContato = this.credor.proximoContato ? new Date(this.credor.proximoContato) : this.credor.proximoContato;
            }
        },

        resetar() {
            this.ocorrenciaTemp = {
                tipoOcorrenciaId: null,
                dataOcorrencia: null,
                texto: null,
                proximoContato: null,
            };
            this.$nextTick(() => {
                this.v$.$reset();
            });
        },

        confirmarSalvar() {
            this.$confirm.require({
                message: 'Tem certeza que deseja prosseguir?',
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },

        salvar() {
            this.$emit('salvar', this.ocorrenciaTemp);
        },
    },

    watch: {
        mostrar() {
            this.display = this.mostrar;
            if (this.mostrar) {
                this.obterTiposOcorrencias();
            }
        },

        reset() {
            if (this.reset) {
                this.resetar();
                this.$emit('resetado');
            }
        },
    },

    computed: {
        naoPermiteSalvar() {
            return this.v$.$invalid;
        },

        yearRange() {
            let anoFinal = new Date().getFullYear();
            let anoInicial = anoFinal - 1;
            return `${anoInicial}:${anoFinal}`;
        },

        yearRange2() {
            let anoFinal = new Date().getFullYear() + 1;
            let anoInicial = anoFinal - 1;
            return `${anoInicial}:${anoFinal}`;
        },
    },
};
</script>
