<template>
    <Dialog
        v-model:visible="display"
        :modal="true"
        @hide="cancelar()"
        @show="preencher()"
        :breakpoints="{ '960px': '75vw', '640px': '95vw' }"
        :style="{ width: '50vw' }"
        :contentStyle="{ overflow: 'visible' }"
    >
        <template #header>
            <h5>{{ titulo }}</h5>
        </template>
        <erros-box :erros="erros"></erros-box>
        <div class="p-formgrid p-grid p-fluid">
            <div class="p-field p-col-12 p-md-6">
                <label class="required">Tipo</label>
                <Dropdown v-model="anexoTemp.tipoDocumentoId" :options="tiposDocumentos" optionLabel="descricao" optionValue="tipoDocumentoId" />
            </div>
            <div class="p-field p-col-12 p-md-12">
                <label>Descrição</label>
                <InputText type="text" v-model="anexoTemp.descricao" />
            </div>
            <div class="p-field p-col-12">
                <label class="required">Arquivo</label>
                <FileUpload
                    :customUpload="true"
                    @uploader="arquivoSelecionado"
                    mode="basic"
                    chooseLabel="Selecionar Arquivo"
                    :auto="true"
                    v-if="!anexoTemp.arquivo"
                />
                <div v-if="anexoTemp.arquivo">
                    <strong>{{ nomeArquivo }}</strong>
                    <i class="pi pi-trash p-error icon-button p-ml-2" style="fontsize: 1.2rem" @click="limparArquivo()"></i>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary" @click="cancelar()" autofocus />
            <Button label="Salvar" icon="pi pi-check" @click="confirmarSalvar()" :disabled="naoPermiteSalvar" />
        </template>
    </Dialog>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import AnexosService from './services';

export default {
    setup() {
        return { v$: useVuelidate() };
    },

    props: {
        mostrar: {
            type: Boolean,
            default: false,
        },

        titulo: {
            type: String,
        },

        erros: {
            type: Array,
        },

        reset: {
            type: Boolean,
            default: false,
        },

        acao: {
            type: String,
            default: 'I',
        },

        credor: {
            type: Object,
        },
    },

    emits: ['cancelar', 'salvar', 'resetado'],

    data() {
        return {
            display: false,
            anexoTemp: {
                tipoDocumentoId: null,
                descricao: null,
                arquivo: null,
            },
            tiposDocumentos: [],
            nomeArquivo: null,
            arquivoAlterado: false,
        };
    },

    validations() {
        return {
            anexoTemp: {
                tipoDocumentoId: { required },
                arquivo: { required },
            },
        };
    },

    methods: {
        obterTiposDocumentos() {
            this.$store.dispatch('addRequest');
            AnexosService.obterTiposDocumentos().then((response) => {
                if (response && response.success) {
                    this.tiposDocumentos = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        cancelar() {
            this.resetar();
            this.$emit('cancelar');
        },

        preencher() {},

        resetar() {
            this.anexoTemp = {
                tipoDocumentoId: null,
                descricao: null,
                arquivo: null,
            };
            this.$nextTick(() => {
                this.v$.$reset();
            });
        },

        confirmarSalvar() {
            this.$confirm.require({
                message: 'Tem certeza que deseja prosseguir?',
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },

        salvar() {
            this.$emit('salvar', this.anexoTemp);
        },

        arquivoSelecionado(event) {
            this.anexoTemp.arquivo = event.files[0];
            this.nomeArquivo = this.anexoTemp.arquivo.name;
        },

        limparArquivo() {
            this.anexoTemp.arquivo = null;
            this.arquivoAlterado = true;
        },
    },

    watch: {
        mostrar() {
            this.display = this.mostrar;
            if (this.mostrar) {
                this.obterTiposDocumentos();
            }
        },

        reset() {
            if (this.reset) {
                this.resetar();
                this.$emit('resetado');
            }
        },
    },

    computed: {
        naoPermiteSalvar() {
            return this.v$.$invalid;
        },

        yearRange() {
            let anoFinal = new Date().getFullYear();
            let anoInicial = anoFinal - 1;
            return `${anoInicial}:${anoFinal}`;
        },

        yearRange2() {
            let anoFinal = new Date().getFullYear() + 1;
            let anoInicial = anoFinal - 1;
            return `${anoInicial}:${anoFinal}`;
        },
    },
};
</script>
