<template>
    <tabela
        :data="anexos"
        dataKey="credorAnexoId"
        :globalFilterFields="['tipoDocumento', 'descricao', 'nomeArquivo', 'dataAtualizacao', 'usuarioAtualizacao']"
        stateKey="dt-anexos"
        :small="true"
    >
        <template #botoes>
            <btn-inserir @atualizarAnexos="atualizarAnexos($event)" v-if="mostrarBtnInserir" :credor="credor"></btn-inserir>
        </template>
        <template #conteudo>
            <Column headerStyle="width: 3em">
                <template #body="slotProps">
                    <div class="p-text-nowrap">
                        <btn-excluir
                            class="p-ml-1"
                            :anexo="slotProps.data"
                            v-if="mostrarBtnExcluir"
                            @atualizarAnexos="atualizarAnexos($event)"
                        ></btn-excluir>
                        <btn-download class="p-ml-1" :anexo="slotProps.data"></btn-download>
                    </div>
                </template>
            </Column>
            <Column field="tipoDocumento" header="Tipo" :sortable="true" />
            <Column field="descricao" header="Descrição" :sortable="true" />
            <Column field="nomeArquivo" header="Arquivo" :sortable="true" />
            <Column field="dataAtualizacao" header="Atualizado em" :sortable="true">
                <template #body="slotProps">
                    <div class="p-text-nowrap">
                        {{ $dateFormat(slotProps.data.dataAtualizacao, 'DD/MM/YYYY HH:mm') }}
                    </div>
                    <div class="p-text-nowrap">por {{ slotProps.data.usuarioAtualizacao }}</div>
                </template>
            </Column>
        </template>
    </tabela>
</template>

<script>
import BtnInserir from './BtnInserir';
import BtnExcluir from './BtnExcluir';
import BtnDownload from './BtnDownload';

export default {
    props: {
        credor: {
            type: Object,
        },
    },

    components: {
        BtnInserir,
        BtnExcluir,
        BtnDownload,
    },

    data() {
        return {
            anexos: [],
        };
    },

    methods: {
        atualizarAnexos() {
            this.$emit('obterCredorAtualizado');
        },
    },

    mounted() {},

    computed: {
        mostrarBtnInserir() {
            return this.$temAcessoView('CREDORES-ANEXOS-01');
        },

        mostrarBtnExcluir() {
            return this.$temAcessoView('CREDORES-ANEXOS-02');
        },
    },

    watch: {
        credor() {
            if (this.credor) {
                this.anexos = this.credor.anexos;
            }
        },
    },
};
</script>
