<template>
    <font-awesome-icon icon="trash-alt" @click="confirmarExcluir()" size="lg" class="icon-button p-error" title="Excluir" />
</template>

<script>
import OcorrenciasServices from './services';

export default {
    props: {
        ocorrencia: {
            type: Object,
        },
    },

    emits: ['atualizarOcorrencias'],

    methods: {
        confirmarExcluir() {
            this.$confirm.require({
                message: `Tem certeza que deseja excluir a ocorrência?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.excluir();
                },
            });
        },

        excluir() {
            this.$store.dispatch('addRequest');
            let ocorrenciaDto = {
                ocorrenciaId: this.ocorrencia.ocorrenciaId,
                credorId: this.$route.params.id,
            };
            OcorrenciasServices.excluir(ocorrenciaDto).then(response => {
                if (response && response.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Exclusão da Ocorrência',
                        detail: 'Ocorrência excluída com sucesso',
                        life: 3000,
                    });
                    this.$emit('atualizarOcorrencias', response.data);
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Exclusão da Ocorrência',
                        detail: response.errors[0],
                        life: 3000,
                        group: 'dialog',
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
