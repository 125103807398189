<template>
    <tabela :data="ocorrencias" dataKey="ocorrenciaId" :globalFilterFields="['dataOcorrencia', 'tipoOcorrencia', 'texto', 'dataCadastro', 'dataAtualizacao', 'usuarioCadastro', 'usuarioAtualizacao']" stateKey="dt-ocorrencias" :small="true">
        <template #botoes>
            <btn-inserir @atualizarOcorrencias="atualizarOcorrencias($event)" v-if="mostrarBtnInserir" :credor="credor"></btn-inserir>
        </template>
        <template #conteudo>
            <Column headerStyle="width: 3em">
                <template #body="slotProps">
                    <div class="p-text-nowrap">
                        <btn-atualizar :ocorrencia="slotProps.data" v-if="mostrarBtnAtualizar" @atualizarOcorrencias="atualizarOcorrencias($event)" :credor="credor"></btn-atualizar>
                        <btn-excluir class="p-ml-1" :ocorrencia="slotProps.data" v-if="mostrarBtnExcluir" @atualizarOcorrencias="atualizarOcorrencias($event)"></btn-excluir>
                    </div>
                </template>
            </Column>
            <Column field="dataOcorrencia" header="Data Ocorrência" :sortable="true">
                <template #body="slotProps">
                    {{ $dateFormat(slotProps.data.dataOcorrencia, 'DD/MM/YYYY') }}
                </template>
            </Column>
            <Column field="tipoOcorrencia" header="Tipo" :sortable="true" />
            <Column field="texto" header="Ocorrência" :sortable="true" />
            <!-- <Column field="pessoa" header="Cadastrada Por" :sortable="true" /> -->
            <Column field="dataCadastro" header="Cadastrada em" :sortable="true">
                <template #body="slotProps">
                    <div class="p-text-nowrap">{{ $dateFormat(slotProps.data.dataCadastro, 'DD/MM/YYYY HH:mm') }}</div>
                    <div class="p-text-nowrap">por {{ slotProps.data.usuarioCadastro }}</div>
                </template>
            </Column>
            <!-- <Column field="usuarioAtualizacao" header="Atualizada Por" :sortable="true" /> -->
            <Column field="dataAtualizacao" header="Atualizado em" :sortable="true">
                <template #body="slotProps">
                    <div class="p-text-nowrap">
                        {{ $dateFormat(slotProps.data.dataAtualizacao, 'DD/MM/YYYY HH:mm') }}
                    </div>
                    <div class="p-text-nowrap">por {{ slotProps.data.usuarioAtualizacao }}</div>
                </template>
            </Column>
        </template>
    </tabela>
</template>

<script>
import BtnInserir from './BtnInserir';
import BtnAtualizar from './BtnAtualizar';
import BtnExcluir from './BtnExcluir';

export default {
    props: {
        credor: {
            type: Object,
        },
    },

    components: {
        BtnInserir,
        BtnAtualizar,
        BtnExcluir,
    },

    data() {
        return {
            ocorrencias: [],
        };
    },

    methods: {
        atualizarOcorrencias() {
            this.$emit('obterCredorAtualizado');
        },
    },

    mounted() {},

    computed: {
        mostrarBtnInserir() {
            return this.$temAcessoView('OCORRENCIAS-01');
        },

        mostrarBtnAtualizar() {
            return this.$temAcessoView('OCORRENCIAS-02');
        },

        mostrarBtnExcluir() {
            return this.$temAcessoView('OCORRENCIAS-03');
        },
    },

    watch: {
        credor() {
            if (this.credor) {
                this.ocorrencias = this.credor.ocorrencias;
            }
        },
    },
};
</script>
