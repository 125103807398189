<template>
    <Button label="Nova Consulta" icon="pi pi-plus" iconPos="left" @click="confirmarInserir()" class="p-button" />
</template>

<script>
import Services from './services';

export default {
    props: {
        credor: {
            type: Object,
        },
    },

    components: {},

    emits: ['atualizarConsultas'],

    data() {
        return {};
    },

    methods: {
        confirmarInserir() {
            this.$confirm.require({
                message: `Tem certeza que deseja realizar uma nova consulta para o credor ${this.credor.nome}?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.inserir();
                },
            });
        },

        inserir() {
            this.$store.dispatch('addRequest');
            let novaConsultaDto = {
                credorId: this.$route.params.id,
            };
            Services.inserir(novaConsultaDto).then((r) => {
                if (r && r.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Nova consulta',
                        detail: 'Nova consulta realizada com sucesso',
                        life: 3000,
                    });
                    this.$emit('atualizarConsultas', r.data);
                } else {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Nova consulta',
                        detail: r.errors[0],
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
