import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PROJETOS_PORT}${process.env.VUE_APP_API_PATH}`;
const urlBase = `${api}/credores/consultas`;

export default {

    obterPorId(consultaId) {
        return axiosJwt.get(`${urlBase}/${consultaId}`);
    },

    inserir(consultaDto) {
        return axiosJwt.post(`${urlBase}/inserir`, consultaDto);
    },

    atualizarCredor(atualizacaoDto) {
        return axiosJwt.post(`${urlBase}/atualizar`, atualizacaoDto);
    },
};
