<template>
    <detalhe titulo="Logradouro" :size="size">{{ endereco?.logradouro }}</detalhe>
    <detalhe titulo="Número" :size="size">{{ endereco?.numero }}</detalhe>
    <detalhe titulo="Complemento" :size="size">{{ endereco?.complemento }}</detalhe>
    <detalhe titulo="Bairro" :size="size">{{ endereco?.bairro }}</detalhe>
    <detalhe titulo="Cidade" :size="size">{{ endereco?.cidade }}</detalhe>
    <detalhe titulo="UF" :size="size">{{ endereco?.uf }}</detalhe>
    <detalhe titulo="CEP" :size="size">{{ $toCep(endereco?.cep) }}</detalhe>
</template>

<script>
export default {
    props: {
        endereco: {
            type: Object,
        },

        size: {
            type: String,
            default: '100',
        },
    },
};
</script>
