<template>
    <detalhe :titulo="credor?.tipoPessoa == 'PF' ? 'CPF' : 'CNPJ'" :size="size">{{ credor?.tipoPessoa == 'PF' ? $toCpf(credor?.cpfCnpj) : $toCnpj(credor?.cpfCnpj) }}</detalhe>
    <detalhe titulo="Situação Cadastral" :size="size" v-if="isCessaoCredito">{{ credor?.situacaoCadastralDescr }}</detalhe>
    <detalhe titulo="Classe" :size="size" v-if="isRecuperacaoJudicial">{{ credor?.classe }}</detalhe>
    <detalhe titulo="Natureza" :size="size">{{ credor?.naturezaDescr }}</detalhe>
    <detalhe titulo="Origem" :size="size">{{ credor?.origemDescr }}</detalhe>
    <detalhe titulo="Histórico Jurídico" :size="size">{{ credor?.historicoJuridico }}</detalhe>
    <detalhe titulo="Valor Nominal" :size="size" v-if="isCessaoCredito">{{ $numeral(credor?.valorNominal).format('$0,0.00') }}</detalhe>
    <detalhe titulo="Valor Corrigido" :size="size" v-if="isCessaoCredito">{{ $numeral(credor?.valorCorrigido).format('$0,0.00') }}</detalhe>
    <detalhe titulo="Preco Target" :size="size" v-if="isCessaoCredito">{{ $numeral(credor?.precoTarget).format('$0,0.00') }}</detalhe>
    <detalhe titulo="Preco Máximo" :size="size" v-if="isCessaoCredito">{{ $numeral(credor?.precoMaximo).format('$0,0.00') }}</detalhe>
    <detalhe titulo="Valor QGC" :size="size" v-if="isRecuperacaoJudicial">{{ $numeral(credor?.valorQGC).format('$0,0.00') }}</detalhe>
    <detalhe titulo="Valor do Plano" :size="size" v-if="isRecuperacaoJudicial">{{ $numeral(credor?.valorPlano).format('$0,0.00') }}</detalhe>
    <detalhe titulo="Status Contato" :size="size">{{ credor?.statusContatoDescr }}</detalhe>
    <detalhe titulo="Interesse" :size="size" v-if="isCessaoCredito">{{ credor?.statusInteresseDescr }}</detalhe>
    <detalhe titulo="Status Negociação" :size="size" v-if="isCessaoCredito">{{ credor?.statusNegociacaoDescr }}</detalhe>
    <detalhe titulo="Status Proposta" :size="size">{{ credor?.statusPropostaDescr }}</detalhe>
    <detalhe titulo="Status Cessão" :size="size" v-if="isCessaoCredito">{{ credor?.statusCessaoDescr }}</detalhe>
    <detalhe titulo="Status Procuração" :size="size" v-if="isRecuperacaoJudicial">{{ credor?.statusProcuracaoDescr }}</detalhe>
    <detalhe titulo="Responsavel" :size="size">{{ credor?.responsavel }}</detalhe>
    <detalhe titulo="Observações" :size="size">{{ credor?.observacoes }}</detalhe>
    <detalhe titulo="Atualizado em" :size="size">{{ $dateFormat(credor?.dataAtualizacao, 'DD/MM/YYYY HH:mm') }}</detalhe>
</template>

<script>
export default {
    props: {
        projeto: {
            type: Object,
        },

        credor: {
            type: Object,
        },

        isCessaoCredito: {
            type: Boolean,
            default: () => false,
        },

        isRecuperacaoJudicial: {
            type: Boolean,
            default: () => false,
        },
    },

    data() {
        return {
            size: '150',
        };
    },
};
</script>
