<template>
    <tabela
        v-if="mostrarTabela"
        :data="consultas"
        dataKey="consultaId"
        :globalFilterFields="['tipoDescr', 'codigo', 'statusDescr', 'dataAtualizacao', 'usuarioAtualizacao']"
        stateKey="dt-consultas"
        :small="true"
    >
        <template #botoes>
            <btn-inserir @atualizarConsultas="atualizarConsultas($event)" v-if="mostrarBtnInserir" :credor="credor"></btn-inserir>
        </template>
        <template #conteudo>
            <Column headerStyle="width: 3em">
                <template #body="slotProps">
                    <div class="p-text-nowrap">
                        <font-awesome-icon
                            icon="info-circle"
                            @click.stop="detalhar(slotProps.data.consultaId)"
                            size="lg"
                            class="icon-button p-text-primary"
                            title="Detalhar"
                            v-if="$temAcessoView('CREDORES-CONSULTAS-00')"
                        />
                    </div>
                </template>
            </Column>
            <Column field="tipoDescr" header="API" :sortable="true">
                <template #body="slotProps">
                    <status :status="slotProps.data.tipoDescr"></status>
                </template>
            </Column>
            <Column field="codigo" header="Consulta" :sortable="true" />
            <Column field="statusDescr" header="Status" :sortable="true">
                <template #body="slotProps">
                    <status :status="slotProps.data.statusDescr"></status>
                </template>
            </Column>
            <Column field="dataAtualizacao" header="Atualizado em" :sortable="true">
                <template #body="slotProps">
                    <div class="p-text-nowrap">
                        {{ $dateFormat(slotProps.data.dataAtualizacao, 'DD/MM/YYYY HH:mm') }}
                    </div>
                    <div class="p-text-nowrap">por {{ slotProps.data.usuarioAtualizacao }}</div>
                </template>
            </Column>
        </template>
    </tabela>
    <detalhar
        v-if="!mostrarTabela"
        :consultaId="consultaSelecionada"
        :credor="credor"
        @fechar="consultaSelecionada = null"
        @obterCredorAtualizado="$emit('obterCredorAtualizado')"
    ></detalhar>
</template>

<script>
import BtnInserir from './BtnInserir';
import Detalhar from './Detalhar';

export default {
    props: {
        credor: {
            type: Object,
        },
    },

    emits: ['obterCredorAtualizado'],

    components: {
        BtnInserir,
        Detalhar,
    },

    data() {
        return {
            consultas: [],
            consultaSelecionada: null,
            consulta: null,
        };
    },

    methods: {
        atualizarConsultas(retorno) {
            this.consultas = retorno.consultas;
        },

        detalhar(consultaId) {
            this.consultaSelecionada = consultaId;
        },
    },

    mounted() {},

    computed: {
        mostrarBtnInserir() {
            return this.$temAcessoView('CREDORES-CONSULTAS-01') && this.credor?.cpfCnpj;
        },

        mostrarTabela() {
            return this.consultaSelecionada == null;
        },
    },

    watch: {
        credor() {
            if (this.credor) {
                this.consultas = this.credor.consultas;
            }
        },
    },
};
</script>
